import type { NextPage } from "next";
import { Link } from "../components/Actions";
import { FadeText } from "../components/Animation/FadeText";

import { CutoutAsset } from "../components/CutoutAsset/CutoutAsset";

import mobile from "../assets/404mobile.png";
import desktop from "../assets/404desktop.png";

import SEO from "../components/Seo/Seo";

const Page: NextPage = () => {
  return (
    <>
      <SEO title="404 | Coinage Media" />

      <section className="roadmap-main overflow-hidden">
        <div className="roadmap-header  h-[100vh] bg-coinage-purple relative overflow-hidden">
          <div className="hidden tab:flex absolute overflow-hidden z-0 right-0  w-2/3  h-full about-image-holder">
            <CutoutAsset image={desktop} right={false} color="purple" />
          </div>

          <div className="tab:hidden absolute bottom-0 overflow-hidden z-0 right-0  w-full h-1/2 about-image-holder">
            <CutoutAsset image={mobile} mobile={true} color="purple" />
          </div>
          <div className="content-wrapper z-1 relative">
            <FadeText
              textStyle="display1"
              className="p-4 pt-[20vh] display1  max-w-[720px] text-coinage-white">
              404 ERROR
            </FadeText>

            <p className="body1 p-4 text-left text-coinage-white max-w-[500px] mb-[30px]">
              The page you are looking for does not exist.
            </p>

            <Link analyticsProperty="home" modifier="primary" href="/">
              Back to Home
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Page;
